import React from 'react';
import { Code2, Loader } from 'lucide-react';

const Message = ({ content, role }) => {
  // Parse and format message content
  const formatContent = (text) => {
    // Split into blocks (code blocks and paragraphs)
    const blocks = text.split(/(\n```[\s\S]*?\n```|\n`[^`]*`)/g);

    return blocks.map((block, blockIndex) => {
      // Handle code blocks with language support
      if (block.startsWith('\n```')) {
        const [, language, ...codeLines] = block.split('\n');
        const code = codeLines.slice(0, -1).join('\n');
        
        return (
          <div key={blockIndex} className="my-4 rounded-lg bg-gray-800 overflow-hidden">
            {/* Code header with language tag */}
            {language && language !== '```' && (
              <div className="flex items-center px-4 py-2 bg-gray-700 border-b border-gray-600">
                <Code2 className="w-4 h-4 mr-2" />
                <span className="text-sm text-gray-200">{language.replace('```', '')}</span>
              </div>
            )}
            {/* Code content */}
            <pre className="p-4 text-sm overflow-x-auto">
              <code className="text-gray-100">{code}</code>
            </pre>
          </div>
        );
      }

      // Handle inline code
      if (block.startsWith('\n`')) {
        const code = block.slice(2, -1);
        return (
          <code key={blockIndex} className="px-2 py-1 mx-1 bg-gray-200 rounded text-gray-800 text-sm">
            {code}
          </code>
        );
      }

      // Process regular text with markdown-style formatting
      return block.split('\n').map((line, lineIndex) => {
        if (!line.trim()) return null;

        // Handle bullet points
        if (line.trim().startsWith('•') || line.trim().startsWith('-')) {
          return (
            <li key={`${blockIndex}-${lineIndex}`} className="ml-6 mb-2">
              {line.trim().substring(1).trim()}
            </li>
          );
        }

        // Handle numbered lists
        const numberMatch = line.match(/^\d+\.\s/);
        if (numberMatch) {
          return (
            <li key={`${blockIndex}-${lineIndex}`} className="ml-6 mb-2">
              {line.substring(numberMatch[0].length)}
            </li>
          );
        }

        // Handle headings
        if (line.startsWith('#')) {
          const level = line.match(/^#+/)[0].length;
          const headingText = line.slice(level).trim();
          const HeadingTag = `h${level}`;
          const headingClasses = {
            h1: 'text-2xl font-bold mb-4',
            h2: 'text-xl font-bold mb-3',
            h3: 'text-lg font-bold mb-2',
            h4: 'text-base font-bold mb-2',
          }[HeadingTag] || 'font-bold mb-2';
          
          return (
            <HeadingTag key={`${blockIndex}-${lineIndex}`} className={headingClasses}>
              {headingText}
            </HeadingTag>
          );
        }

        // Handle bold text
        let formattedLine = line.replace(
          /\*\*(.*?)\*\*/g,
          '<strong>$1</strong>'
        );
        
        // Handle italic text
        formattedLine = formattedLine.replace(
          /\*(.*?)\*/g,
          '<em>$1</em>'
        );

        return (
          <p
            key={`${blockIndex}-${lineIndex}`}
            className="mb-2"
            dangerouslySetInnerHTML={{ __html: formattedLine }}
          />
        );
      });
    });
  };

  return (
    <div 
      className={`max-w-[80%] rounded-lg p-4 mb-4 ${
        role === 'user' 
          ? 'bg-primary text-white ml-auto'
          : 'bg-white text-gray-800 border border-gray-200 shadow-sm'
      }`}
    >
      <div className="prose max-w-none">
        {formatContent(content)}
      </div>
    </div>
  );
};

export default Message;