import React from 'react';
import ChatInterface from './components/ChatInterface';
import AuthenticatedApp, { oktaAuth } from './AuthenticatedApp';

function App() {
  return (
    <AuthenticatedApp>
      <div className="min-h-screen flex flex-col bg-gray-100">
        {/* Fixed header */}
        <header className="bg-primary text-white shadow-md">
          <div className="max-w-6xl mx-auto px-4 py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <h1 className="text-2xl font-bold tracking-tight">TeamHealth</h1>
              </div>
              <div className="flex items-center space-x-4">
                <div className="text-sm bg-secondary px-3 py-1 rounded">
                  Powered by Claude
                </div>
                <button
                  onClick={() => oktaAuth.signOut()}
                  className="text-sm bg-secondary hover:bg-opacity-80 px-3 py-1 rounded transition-colors"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main content area with fixed height */}
        <main className="flex-1 max-w-6xl w-full mx-auto px-4 py-4">
          <ChatInterface />
        </main>

        {/* Fixed footer */}
        <footer className="bg-primary text-white py-2">
          <div className="max-w-6xl mx-auto px-4 text-sm text-center">
            © {new Date().getFullYear()} TeamHealth. All rights reserved.
          </div>
        </footer>
      </div>
    </AuthenticatedApp>
  );
}

export default App;