import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Send, Upload, Loader } from 'lucide-react';
import { oktaAuth } from '../AuthenticatedApp';
import Message from './Message';


const ChatInterface = () => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    return savedMessages ? JSON.parse(savedMessages) : [
      { role: 'assistant', content: "Hi there! I'm your TeamHealth assistant. How can I help you today?" }
    ]; 
  });
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [sessionState, setSessionState] = useState(null); // Don't load from localStorage
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  // Only save messages to localStorage
  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = input.trim();
    setInput('');
    setLoading(true);
    setError(null);

    setMessages(prev => [...prev, { role: 'user', content: userMessage }]);

    try {
      const token = await oktaAuth.getAccessToken();

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          message: userMessage,
          sessionId: sessionState
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      setSessionState(data.sessionId); // Update session ID from Bedrock
      setMessages(prev => [...prev, { 
        role: 'assistant', 
        content: data.message
      }]);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to send message. Please try again.');
      setMessages(prev => prev.slice(0, -1));
    } finally {
      setLoading(false);
    }
  };

  // Clear both local storage and session state on sign out
  const handleSignOut = useCallback(() => {
    localStorage.removeItem('chatMessages');
    setSessionState(null);
    setMessages([
      { role: 'assistant', content: "Hi there! I'm your TeamHealth assistant. How can I help you today?" }
    ]);
  }, []);

  useEffect(() => {
    oktaAuth.authStateManager.subscribe(handleSignOut);
    return () => oktaAuth.authStateManager.unsubscribe(handleSignOut);
  }, [handleSignOut]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);
    setError(null);

    try {
        const token = await oktaAuth.getAccessToken();
        const newSessionId = `session_${Date.now()}_${oktaAuth.getUser().email}`;
        const filename = encodeURIComponent(file.name);
        const uploadUrl = `${process.env.REACT_APP_API_ENDPOINT}/upload?filename=${filename}&sessionId=${encodeURIComponent(sessionState || `session_${Date.now()}_${oktaAuth.getUser().email}`)}`;

        const uploadResponse = await fetch(uploadUrl, {
            method: 'POST',
            body: file,
            headers: {
                'Content-Type': file.type || 'application/octet-stream',
                'Authorization': `Bearer ${token}`
            },
        });

        // Store the new session ID if we didn't have one
        if (!sessionState) {
          setSessionState(newSessionId);
        }

        if (!uploadResponse.ok) {
            throw new Error('Upload failed');
        }

        // Add upload notification
        setMessages(prev => [...prev, 
            { role: 'user', content: `Uploaded file: ${file.name}` },
            { role: 'assistant', content: "I'm analyzing the file contents..." }
        ]);

        // Wait for processing (most files take 13-17 seconds based on logs)
        await new Promise(resolve => setTimeout(resolve, 18000));

        // Auto-query for analysis
        const analysisResponse = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/chat`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    message: `What did you find in the file ${file.name}?`,
                    sessionId: sessionState
                })
            }
        );

        if (analysisResponse.ok) {
            const analysisData = await analysisResponse.json();
            setMessages(prev => [
                ...prev.slice(0, -1), // Remove "analyzing" message
                { role: 'assistant', content: analysisData.message }
            ]);
        }

    } catch (error) {
        console.error('Upload error:', error);
        setError(error.message || 'Failed to upload file');
        setMessages(prev => prev.slice(0, -1)); // Remove loading message
    } finally {
        setLoading(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }
};

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <div className="bg-primary text-white p-4 rounded-t-lg shadow">
        <h1 className="text-2xl font-bold">TeamHealth Assistant</h1>
      </div>

      <div className="flex-1 overflow-hidden bg-white shadow">
        <div className="h-full overflow-y-auto p-4 space-y-4">
          {messages.map((msg, idx) => (
            <div key={idx} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
              <Message role={msg.role} content={msg.content} />
            </div>
          ))}
          {error && (
            <div className="text-red-500 text-center p-2">
              {error}
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="bg-white p-4 border-t border-gray-200 rounded-b-lg">
        <div className="flex gap-2 mb-4">
          <label className="flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-800 rounded hover:bg-gray-200 cursor-pointer transition-colors">
            <Upload className="w-5 h-5" />
            <span className="font-medium">Upload File</span>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              className="hidden"
              disabled={loading}
            />
          </label>
          {loading && 
            <div className="text-sm text-gray-600 flex items-center gap-2">
              <Loader className="w-4 h-4 animate-spin" />
              Just a moment...
            </div>
          }
        </div>

        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="How can I help you?"
            className="flex-1 p-2 border-2 border-gray-200 rounded focus:outline-none focus:border-primary text-gray-800"
            disabled={loading}
          />
          <button
            type="submit"
            disabled={loading || !input.trim()}
            className="px-4 py-2 bg-primary text-white rounded hover:bg-secondary disabled:opacity-50 disabled:cursor-not-allowed transition-colors font-medium"
          >
            {loading ? <Loader className="w-5 h-5 animate-spin" /> : <Send className="w-5 h-5" />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatInterface;