import React from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter as Router, Route, useHistory, Redirect } from 'react-router-dom';
import { Loader } from 'lucide-react';

export const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
    storage: 'localStorage'
  },
  cookies: {
    secure: true,
    sameSite: 'none'
  }
});

// Loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <Loader className="w-8 h-8 animate-spin text-primary" />
  </div>
);

// Login component
const Login = () => {
  const handleLogin = async () => {
    try {
      await oktaAuth.signInWithRedirect();
    } catch (err) {
      console.error('Login error:', err);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-center text-primary">TeamHealth Login</h2>
        <div className="flex justify-center">
          <button
            onClick={handleLogin}
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded transition-colors"
          >
            Sign in with Okta
          </button>
        </div>
      </div>
    </div>
  );
};

// Custom Login Callback component
const CustomLoginCallback = () => {
  const history = useHistory();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const handleCallback = async () => {
      try {
        // Parse tokens from URL
        const tokens = await oktaAuth.token.parseFromUrl();
        await oktaAuth.tokenManager.setTokens(tokens.tokens);
        
        // Redirect to home
        history.replace('/');
      } catch (err) {
        console.error('Callback error:', err);
        setError(err.message);
        // Redirect back to login after error
        setTimeout(() => {
          history.replace('/login');
        }, 3000);
      }
    };
    
    handleCallback();
  }, [history]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-500">
          Error during login. Redirecting back to login page...
        </div>
      </div>
    );
  }

  return <LoadingSpinner />;
};

// Protected route wrapper component
const PrivateRoute = ({ children, ...rest }) => {
  const [authenticated, setAuthenticated] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuthenticated = await oktaAuth.isAuthenticated();
        if (isAuthenticated) {
          try {
            // Verify we have a valid token
            await oktaAuth.getAccessToken();
            setAuthenticated(true);
          } catch (err) {
            console.error('Token error:', err);
            setAuthenticated(false);
            history.replace('/login');
          }
        } else {
          setAuthenticated(false);
        }
      } catch (err) {
        console.error('Auth check failed:', err);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();

    // Subscribe to token changes
    const tokenSubscription = oktaAuth.authStateManager.subscribe(() => {
      checkAuth();
    });

    return () => {
      tokenSubscription.unsubscribe();
    };
  }, [history]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// Main App wrapper with authentication
// Main App wrapper with authentication
const AuthenticatedApp = ({ children }) => {
  const history = useHistory();  // Add this back

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    history.replace('/login');
  };

  return (
    <Router>
      <Security 
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={customAuthHandler}
      >
        <Route path="/login" component={Login} />
        <Route path="/login/callback" component={CustomLoginCallback} />
        <PrivateRoute exact path="/">
          {children}
        </PrivateRoute>
      </Security>
    </Router>
  );
};

export default AuthenticatedApp;